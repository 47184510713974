import React, { ChangeEvent, useEffect, useState } from 'react';
import './CourseRegistrationForm.css';
import '../../../styles.css';
import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import { ReactComponent as SeekLogo } from '../../../assets/seek-logo.svg';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier } from 'firebase/auth';
import Button from '../../../components/button/Button';
import { COLORS } from '../../../utils/colors';
import PhoneInput from '../../../components/phoneInput/PhoneInput';
import TextInput from '../../../components/textInput/TextInput';
import ErrorComponent from '../../../components/error/Error';
import Loader from '../../../components/loader/Loader';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface SignUpFormProps {
	onFormSubmitHandler: (fullName: string, phoneNumber: string) => void;
	onBackArrowClick: () => void;
	user: any;
	setState: any;
	loading: boolean;
	name?: string;
	phone?: string;
	course_name: string;
}

interface WindowWithRecaptchaVerifier extends Window {
	recaptchaVerifier?: RecaptchaVerifier;
	confirmationResult?: any;
}

declare let window: WindowWithRecaptchaVerifier;

const CourseRegistrationForm: React.FC<SignUpFormProps> = ({
	onFormSubmitHandler,
	course_name,
	user,
	loading,
	onBackArrowClick,
	name,
	phone,
	// proceedRegistrationWithUserExist
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [fullName, setFullName] = useState<string>(name || '');
	const [error, setError] = useState<string | null>(null);
	const [phoneNumber, setPhoneNumber] = useState<string>(phone || '');
	const [loader, setLoader] = useState(false);

	const handleFullNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFullName(event.target.value);
	};

	const customInputStyle: React.CSSProperties = {
		// width: '300px',
		marginTop: '10px',
	};

	const handlePhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	): void => {
		setPhoneNumber(value);
	};

	const customDropdownStyle: React.CSSProperties = {
		// Add your custom dropdown styles here if needed
	};

	const onFormClickHandler = async () => {
		if (!fullName || fullName?.length === 0) {
			setError('Please enter your details.');
			return;
		} else if (phoneNumber === '' || phoneNumber.length < 10) {
			setError('Please enter a valid mobile number.');
			return;
		}
		setLoader(true);
		Mixpanel.track('click_proceed_further_from_register_with_seek', {
			event_action: 'clicked',
			name: fullName,
			phone: phoneNumber,
			course_name: course_name,
			event_page: 'register_with_seek',
		});
		onFormSubmitHandler(fullName, phoneNumber);
	};

	const closeErrorPopup = () => {
		setError('');
	};

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	return (
		<div className={'course-registration-container'}>
			{(loader || loading) && <Loader />}
			{error && (
				<ErrorComponent
					onClose={closeErrorPopup}
					customStyles={{
						position: 'fixed',
						justifyContent: 'flex-start',
						alignItems: 'center',
						top: 10,
						left: 10,
						right: 10,
					}}
					message={error}
				/>
			)}

			<div className={'course-registration-header-container'}>
				<BackArrow width={48} height={48} onClick={onBackArrowClick} />
			</div>

			<div style={{ alignSelf: 'center', marginTop: '12px' }}>
				<SeekLogo
					style={{ alignSelf: 'center', width: '100%' }}
					width={92}
					height={48}
				/>
				<div className={'course-registration-title'}>Register with Seek</div>
				{/*<div className={'course-registration-subtitle'}>Please fill the details to get a 6 digit verification*/}
				{/*    code.*/}
				{/*</div>*/}
			</div>

			<div className={'course-registration-form-title margin-top-30px'}>
				Full Name
			</div>
			<TextInput
				value={fullName}
				onChange={handleFullNameChange}
				placeholder='Enter your full name'
				// style={customInputStyle}
			/>

			<div className={'course-registration-form-title margin-top-20px'}>
				WhatsApp Number
			</div>
			<PhoneInput
				defaultCountry='in'
				onPhoneChange={handlePhoneChange}
				phoneNumber={phoneNumber}
				inputStyle={customInputStyle}
				dropdownStyle={customDropdownStyle}
			/>

			<Button
				isSticky={true}
				disabled={loader}
				style={{
					alignSelf: 'center',
					backgroundColor: loader ? COLORS.LIGHT_GREY : COLORS.PRIMARY,
					marginBottom: '64px',
				}}
				onClick={onFormClickHandler}
				label={'Continue'}></Button>
		</div>
	);
};

export default CourseRegistrationForm;
