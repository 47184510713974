import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthenticatedUsers = (isPublic?: boolean) => {
	const userString = useMemo(() => window.localStorage.getItem('user'), []);
	const navigate = useNavigate();
	const userData = useMemo(
		() => (userString ? JSON.parse(userString) : null),
		[userString]
	);
	useEffect(() => {
		if (!userData && !isPublic) {
			navigate('/login');
		}
	}, [userData]);
	return userData;
};

export default useAuthenticatedUsers;
