import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { storage } from '../../../services/Firebase';
import lineImage from '../../../assets/webp-images/course-benefits-line.png';

type CourseBenefitsItemProps = {
	benefit: { icon: string; title: string };
};

const CourseBenefitsItem: React.FC<CourseBenefitsItemProps> = ({ benefit }) => {
	const [imgUrl, setImgUrl] = useState<string | null>(null);

	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, benefit.icon);
				const url = await getDownloadURL(imageRef);
				console.log(url);
				setImgUrl(url);
			} catch (error) {}
		})();
	}, [benefit]);

	return (
		<div className='course-page-benefits-row-item'>
			{imgUrl && (
				<img
					style={{ width: '40%', marginBottom: '8px' }}
					src={imgUrl}
					alt=''
				/>
			)}
			<p>{benefit.title}</p>
		</div>
	);
};

type Props = {
	benefits: { icon: string; title: string }[];
};

const CourseBenefits: React.FC<Props> = ({ benefits }) => {
	return (
		<div className='course-page-benefits'>
			{benefits.map((item, index) => {
				return <CourseBenefitsItem key={index} benefit={item} />;
			})}
			<img
				style={{ width: '344px', marginTop: '12px' }}
				src={lineImage}
				alt=''
			/>
		</div>
	);
};

export default CourseBenefits;
