import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
let env_check = true;
let actions = {
	identify: (id) => {
		if (env_check) mixpanel.identify(id);
	},
	resetIdentity: () => {
		if (env_check) mixpanel.reset();
	},
	alias: (id) => {
		if (env_check) mixpanel.alias(id);
	},
	track: (name, props) => {
		if (env_check) mixpanel.track(name, props);
	},
	people: {
		set: (props) => {
			if (env_check) mixpanel.people.set(props);
		},
	},
	startRecording: () => {
		if (env_check) mixpanel.start_session_recording();
	},
	stopRecording: () => {
		if (env_check) mixpanel.stop_session_recording();
	},
};

export let Mixpanel = actions;
