import BottomNavigation from '../../components-v2/bottom-nav/BottomNavigation';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';

function HomeContainer() {
	const isPublic = window.location.pathname === '/courses' ? true : false;
	useAuthenticatedUsers(isPublic);

	return <BottomNavigation />;
}

export default HomeContainer;
