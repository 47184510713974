import React, {
	useState,
	CSSProperties,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	CarouselContext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { customerTestimonials, LANDING_COMPONENT } from '../../../utils/utils';
import TestimonialCard from './TestimonialCard';
import LeftDisabledArrow from '../../../assets/new-icons/chevron-left-disabled.svg';
import LeftEnabledArrow from '../../../assets/new-icons/chevron-left-enabled.svg';
import RightDisabledArrow from '../../../assets/new-icons/chevron-right-disabled.svg';
import RightEnabledArrow from '../../../assets/new-icons/chevron-right-enabled.svg';

interface CustomCarouselProps {
	component_name: string;
	naturalSlideWidth: number;
	naturalSlideHeight: number;
	style?: CSSProperties;
	width?: any;
	margin?: any;
	pageName?: string;
	testimonials?: Array<any>;
	dark?: boolean;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
	component_name,
	naturalSlideWidth,
	naturalSlideHeight,
	style,
	width,
	margin,
	dark,
	pageName,
	testimonials,
}) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [playingVideo, setPlayingVideo] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const handleScroll = (e: any) => {
			if (pageName === 'course_desciption_page') {
				setPlayingVideo(true);
			} else if (pageName === 'plan_landing_page') {
				if (window.scrollY >= 0 && window.scrollY < 800) {
					setPlayingVideo(true);
				} else {
					setPlayingVideo(false);
				}
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [pageName]);

	const onVideoPlayPause = (index: number, title: string) => {
		if (index === selectedIndex) {
			setSelectedIndex(-1);
		} else {
			setSelectedIndex(index);
			Mixpanel.track('click_plan_landing_customer_testimonial', {
				event_action: 'clicked',
				event_page: testimonials ? 'course_landing_page' : 'plan_landing_page',
				action: 'play',
				title: title,
			});
		}
	};

	const onBackClick = (e: any) => {
		if (currentIndex > 0) {
			setCurrentIndex((prev) => prev - 1);
			Mixpanel.track('click_to_scroll_testimonials', {
				event_action: 'clicked',
				scroll_direction: 'back',
			});
		}
	};
	const onNextClick = (e: any) => {
		if (currentIndex < 2) {
			setCurrentIndex((prev) => prev + 1);
			Mixpanel.track('click_to_scroll_testimonials', {
				event_action: 'clicked',
				scroll_direction: 'next',
			});
		}
	};

	const contextHandler = (context: any) => {
		setCurrentIndex(context.state.currentSlide);
	};

	const testimonialData = useMemo(
		() => (testimonials ? testimonials : customerTestimonials),
		[testimonials]
	);

	return (
		<CarouselProvider
			naturalSlideWidth={naturalSlideWidth}
			naturalSlideHeight={naturalSlideHeight}
			currentSlide={currentIndex}
			totalSlides={testimonialData?.length}
			visibleSlides={2}>
			<CarouselContext.Consumer>
				{(context) => {
					context.subscribe(() => contextHandler(context));
					return (
						<Slider style={style}>
							{testimonialData?.map((item, index) => (
								<Slide
									style={{
										border: 'none',
										borderRadius:
											component_name === LANDING_COMPONENT.TESTIMONIAL
												? '16px'
												: '0',
									}}
									key={index}
									index={index}>
									{component_name === LANDING_COMPONENT.TESTIMONIAL && (
										<TestimonialCard
											dark={dark}
											playingVideo={playingVideo}
											index={index}
											selectedIndex={selectedIndex}
											onVideoPlayPause={onVideoPlayPause}
											name={testimonials ? item.module : item.name}
											location={item.location}
											firebase={testimonials ? true : false}
											video={testimonials ? item.video : item.videoUrl}
										/>
									)}
								</Slide>
							))}
						</Slider>
					);
				}}
			</CarouselContext.Consumer>
			{testimonialData?.length > 2 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						marginTop: '16px',
						alignItems: 'center',
					}}>
					<ButtonBack
						onClick={onBackClick}
						style={{
							width: '36px',
							marginRight: '12px',
							height: '36px',
							display: 'flex',
							marginLeft: 'auto',
							border:
								currentIndex === 0 ? '1px solid #D6D6D6' : '1px solid #4462EF',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							backgroundColor: '#F4F4F4',
						}}>
						{currentIndex === 0 ? (
							<img
								src={LeftDisabledArrow}
								alt=''
								style={{ width: '20px', height: '20px' }}
							/>
						) : (
							<img
								src={LeftEnabledArrow}
								alt=''
								style={{ width: '20px', height: '20px' }}
							/>
						)}
					</ButtonBack>
					<ButtonNext
						onClick={onNextClick}
						style={{
							width: '36px',
							height: '36px',
							display: 'flex',
							marginRight: '12px',
							border:
								currentIndex >= testimonialData.length - 2
									? '1px solid #D6D6D6'
									: '1px solid #4462EF',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							backgroundColor: '#F4F4F4',
						}}>
						{currentIndex >= testimonialData.length - 2 ? (
							<img
								src={RightDisabledArrow}
								alt=''
								style={{ width: '20px', height: '20px' }}
							/>
						) : (
							<img
								src={RightEnabledArrow}
								alt=''
								style={{ width: '20px', height: '20px' }}
							/>
						)}
					</ButtonNext>
				</div>
			)}
		</CarouselProvider>
	);
};

export default CustomCarousel;
