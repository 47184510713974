import React, { useRef, useState } from 'react';
import liveIcon from '../../../assets/new-icons/course-live-dark-icon.svg';
import durationIcon from '../../../assets/new-icons/course-duration-dark-icon.svg';
import timeIcon from '../../../assets/new-icons/course-time-dark-icon.svg';
import dropdownIcon from '../../../assets/new-icons/dropdown-arrow.svg';

type CourseCurriculumDayProps = {
	day: {
		week: string | null;
		availability: string | null;
		live_class_time: string | null;
		title: string | null;
		evening_practice: string | null;
		journaling: string | null;
		module: string | null;
		task_description: string | null;
		tag: string | null;
		recorded_class_time: string | null;
		morning_practice: string | null;
		day_bio: string | null;
		assessments: string | null;
	};
};

const CourseCurriculumDay: React.FC<CourseCurriculumDayProps> = ({ day }) => {
	if (day.tag === 'Rest_Day') {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '4px',
					marginTop: '8px',
				}}>
				<div style={{ background: '#EB5757', height: '1px', flex: 1 }}></div>
				<p
					style={{
						color: '#EB5757',
						marginBottom: 0,
						fontFamily: 'Gordita-Medium',
						fontSize: '12px',
					}}>
					Rest Day
				</p>
				<div style={{ background: '#EB5757', height: '1px', flex: 1 }}></div>
			</div>
		);
	}
	if (day.tag === 'One_One_Interaction') {
		return (
			<div className='course-curriculum-week-day-1on1'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p className='course-curriculum-week-day-module-text-title'>
							{day.module}
						</p>
						<p className='course-curriculum-week-day-module-text-subtitle'>
							{day.task_description}
						</p>
					</div>
					<div
						style={{ backgroundColor: '#fff' }}
						className='course-curriculum-week-day-module-tag'>
						1:1 interaction
					</div>
				</div>
				{!!day.day_bio && (
					<p className='course-curriculum-week-day-bio'>{day.day_bio}</p>
				)}
			</div>
		);
	}
	if (day.tag === 'Live_Class') {
		return (
			<div className='course-curriculum-week-day-live'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p className='course-curriculum-week-day-module-text-title'>
							{day.module}
						</p>
						<p className='course-curriculum-week-day-module-text-subtitle'>
							{day.task_description}
						</p>
					</div>
					<div
						style={{ backgroundColor: '#A0E4BD' }}
						className='course-curriculum-week-day-module-tag'>
						Live class
					</div>
				</div>
				<div className='course-curriculum-week-day-details'>
					<div>
						<img src={liveIcon} alt='' />
						<p className='course-curriculum-week-day-details-text'>
							{day.live_class_time}
						</p>
					</div>
					<div
						style={{
							height: '16px',
							width: '1px',
							backgroundColor: '#4a4a4a',
						}}></div>
					<div>
						<img src={timeIcon} alt='' />
						<p className='course-curriculum-week-day-details-text'>
							{day.availability}
						</p>
					</div>
				</div>
				{!!day.day_bio && (
					<p className='course-curriculum-week-day-bio'>{day.day_bio}</p>
				)}
			</div>
		);
	}
	if (day.tag === 'New_Class') {
		return (
			<div className='course-curriculum-week-day-new'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p className='course-curriculum-week-day-module-text-title'>
							{day.module}
						</p>
						<p className='course-curriculum-week-day-module-text-subtitle'>
							{day.task_description}
						</p>
					</div>
					<div>
						<div
							style={{ backgroundColor: '#F9F0FE' }}
							className='course-curriculum-week-day-module-tag'>
							New class
						</div>
					</div>
				</div>
				<div className='course-curriculum-week-day-details'>
					<div>
						<img src={durationIcon} alt='' />
						<p className='course-curriculum-week-day-details-text'>
							{day.recorded_class_time}
						</p>
					</div>
					<div
						style={{
							height: '16px',
							width: '1px',
							backgroundColor: '#4a4a4a',
						}}></div>
					<div>
						<img src={timeIcon} alt='' />
						<p className='course-curriculum-week-day-details-text'>
							{day.availability}
						</p>
					</div>
				</div>
				{!!day.day_bio && (
					<p className='course-curriculum-week-day-bio'>{day.day_bio}</p>
				)}
			</div>
		);
	}
	if (day.tag === 'Self_Practices') {
		return (
			<div className='course-curriculum-week-day-new'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p className='course-curriculum-week-day-module-text-title'>
							{day.module}
						</p>
						<p className='course-curriculum-week-day-module-text-subtitle'>
							{day.task_description}
						</p>
					</div>
					<div
						style={{ backgroundColor: '#FFF3E4' }}
						className='course-curriculum-week-day-module-tag'>
						Self practices
					</div>
				</div>
				<div className='course-curriculum-week-day-info'>
					{!!day.morning_practice && (
						<div className='course-curriculum-week-day-info-item'>
							<p className='course-curriculum-week-day-info-item-title'>
								Morning practices
							</p>
							<p className='course-curriculum-week-day-info-item-subtitle'>
								{day.morning_practice}
							</p>
						</div>
					)}
					{!!day.evening_practice && (
						<div className='course-curriculum-week-day-info-item'>
							<p className='course-curriculum-week-day-info-item-title'>
								Evening practices
							</p>
							<p className='course-curriculum-week-day-info-item-subtitle'>
								{day.evening_practice}
							</p>
						</div>
					)}
					{!!day.assessments && (
						<div className='course-curriculum-week-day-info-item'>
							<p className='course-curriculum-week-day-info-item-title'>
								Assessment
							</p>
							<p className='course-curriculum-week-day-info-item-subtitle'>
								{day.assessments}
							</p>
						</div>
					)}
					{!!day.journaling && (
						<div className='course-curriculum-week-day-info-item'>
							<p className='course-curriculum-week-day-info-item-title'>
								Journaling
							</p>
							<p className='course-curriculum-week-day-info-item-subtitle'>
								{day.journaling}
							</p>
						</div>
					)}
				</div>
				{!!day.day_bio && (
					<p className='course-curriculum-week-day-bio'>{day.day_bio}</p>
				)}
			</div>
		);
	}
	return <></>;
};

type Props = {
	curriculum: {
		[key: string]: {
			week: string | null;
			availability: string | null;
			live_class_time: string | null;
			title: string | null;
			evening_practice: string | null;
			journaling: string | null;
			module: string | null;
			task_description: string | null;
			tag: string | null;
			recorded_class_time: string | null;
			morning_practice: string | null;
			day_bio: string | null;
			assessments: string | null;
		}[];
	};
};

function CourseCurriculum({ curriculum }: Props) {
	const [currentIndex, setCurrentIndex] = useState(0);

	const curriculumRef = useRef<HTMLDivElement>(null);

	return (
		<div>
			{Object.values(curriculum).map((week, index) => {
				const onWeekClick = () => {
					setCurrentIndex(index);
					if (currentIndex === index) {
						setCurrentIndex(-1);
					}
				};
				return (
					<div
						ref={curriculumRef}
						style={{ height: currentIndex === index ? 'max-content' : '52px' }}
						key={index}
						className='course-curriculum-week'>
						<div
							onClick={onWeekClick}
							className='course-curriculum-week-header'>
							<p className='course-curriculum-week-title'>
								{Object.keys(curriculum)[index]}
							</p>
							<img
								style={{
									transform: currentIndex === index ? '' : 'rotate(180deg)',
								}}
								src={dropdownIcon}
								alt=''
							/>
						</div>
						<div>
							{week.map((day, index: number) => {
								return (
									<>
										<p className='course-curriculum-week-day-title'>
											{day.title}
										</p>
										<CourseCurriculumDay day={day} />
										{index !== week.length - 1 && (
											<div className='course-curriculum-week-day-divider'></div>
										)}
									</>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default CourseCurriculum;
