import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './BottomNavigation.css';
import { ReactComponent as LiveClass } from '../../assets/new-icons/live-class.svg';
import { ReactComponent as HomeIcon } from '../../assets/new-icons/home.svg';
import { ReactComponent as Profile } from '../../assets/new-icons/profile.svg';
import { ReactComponent as SelectedHome } from '../../assets/new-icons/selected-home.svg';
import { ReactComponent as SelectedLiveClass } from '../../assets/new-icons/selected-live-class.svg';
import { ReactComponent as SelectedProfile } from '../../assets/new-icons/selected-profile.svg';
import { ReactComponent as SelectedCourse } from '../../assets/new-icons/course-selected.svg';
import { ReactComponent as Course } from '../../assets/new-icons/course.svg';
import Home from '../../screens/home-v2/Home';
import LiveClassContainer from '../../screens/live-class/LiveClassContainer';
import { useDispatch, useSelector } from 'react-redux';
import ProfileContainer from '../../screens/profile/ProfileConatiner';
import { Mixpanel } from '../../analytics/Mixpanel';
import { updateEventsStatus } from '../../store/features/EventsSlices';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CourseContainer from '../../screens/course/CourseContainer';

const BottomNavigation = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const isPublic = searchParams.get('public');

	const [activeTab, setActiveTab] = useState(
		window.location.pathname === '/courses' ? 2 : 1
	);

	const dispatch = useDispatch();
	const view_bottom_nav = useSelector(
		(state: any) => state?.events?.eventsLog?.view_bottom_nav
	);
	const view_main_header = useSelector(
		(state: any) => state?.events?.eventsLog?.view_main_header
	);

	const handleTabSelect = (index: number) => {
		if (!localStorage.getItem('user') && index !== 2) {
			navigate('/login');
		} else {
			setActiveTab(index);
			updateUrl(index);
		}
	};

	const updateUrl = (index: number) => {
		switch (index) {
			case 0:
				navigate('/home');
				break;
			case 1:
				navigate('/live-classes');
				break;
			case 2:
				navigate('/courses');
				break;
			case 3:
				navigate('/profile');
				break;
			default:
				navigate('/home');
		}
	};

	useEffect(() => {
		if (!view_bottom_nav) {
			Mixpanel.track('view_bottom_nav', {
				event_action: 'viewed',
			});
			dispatch(updateEventsStatus({ key: 'view_bottom_nav', value: true }));
		}
	}, [view_bottom_nav]);

	useEffect(() => {
		if (!view_main_header) {
			Mixpanel.track('view_main_header', {
				event_action: 'viewed',
			});
			dispatch(updateEventsStatus({ key: 'view_main_header', value: true }));
		}
	}, [view_main_header]);

	useEffect(() => {
		if (localStorage.getItem('isAngerVisited') !== 'true') {
			Mixpanel.track('view_new_course_tag', {
				event_action: 'viewed',
			});
		}
	}, []);

	return (
		<div className={'bottom-nav-container'}>
			<Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
				<TabPanel>
					{activeTab === 0 && <Home />}
					{activeTab === 1 && <LiveClassContainer />}
					{activeTab === 2 && <CourseContainer />}
					{activeTab === 3 && <ProfileContainer />}
				</TabPanel>
				<TabPanel>
					{activeTab === 0 && <Home />}
					{activeTab === 1 && <LiveClassContainer />}
					{activeTab === 2 && <CourseContainer />}
					{activeTab === 3 && <ProfileContainer />}
				</TabPanel>
				<TabPanel>
					{activeTab === 0 && <Home />}
					{activeTab === 1 && <LiveClassContainer />}
					{activeTab === 2 && <CourseContainer />}
					{activeTab === 3 && <ProfileContainer />}
				</TabPanel>
				<TabPanel>
					{activeTab === 0 && <Home />}
					{activeTab === 1 && <LiveClassContainer />}
					{activeTab === 2 && <CourseContainer />}
					{activeTab === 3 && <ProfileContainer />}
				</TabPanel>
				{
					<TabList
						style={
							window.location.pathname === '/courses' && isPublic
								? { opacity: 0, pointerEvents: 'none' }
								: {}
						}
						className={
							activeTab !== 2
								? 'bottom-nav-tab-list-three'
								: 'bottom-nav-tab-list-three dark'
						}>
						<Tab>
							<div
								className='bottom-nav-tab-list-item-box'
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{activeTab === 0 ? <SelectedHome /> : <HomeIcon />}

								<span
									className={`bottom-nav-card-tag-title ${
										activeTab === 0 ? 'selected' : ''
									}`}>
									Home
								</span>
								{activeTab === 0 && (
									<svg
										style={{ marginTop: '4px' }}
										xmlns='http://www.w3.org/2000/svg'
										width='43'
										height='2'
										viewBox='0 0 43 2'
										fill='none'>
										<path
											d='M1.16669 1H41.1667'
											stroke='#FAA53D'
											stroke-width='2'
											stroke-linecap='square'
										/>
									</svg>
								)}
							</div>
						</Tab>
						<Tab>
							<div
								className='bottom-nav-tab-list-item-box'
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{activeTab === 1 ? <SelectedLiveClass /> : <LiveClass />}
								<span
									className={`bottom-nav-card-tag-title ${
										activeTab === 1 ? 'selected' : ''
									}`}>
									Live Classes
								</span>
								{activeTab === 1 && (
									<svg
										style={{ marginTop: '4px' }}
										xmlns='http://www.w3.org/2000/svg'
										width='43'
										height='2'
										viewBox='0 0 43 2'
										fill='none'>
										<path
											d='M1.16669 1H41.1667'
											stroke='#FAA53D'
											stroke-width='2'
											stroke-linecap='square'
										/>
									</svg>
								)}
							</div>
						</Tab>
						<Tab>
							<div
								className='bottom-nav-tab-list-item-box'
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative',
								}}>
								{activeTab === 2 ? <SelectedCourse /> : <Course />}
								{localStorage.getItem('isAngerVisited') !== 'true' ? (
									<p className='course-tab-badge'>NEW</p>
								) : (
									<></>
								)}
								<span
									className={`bottom-nav-card-tag-title ${
										activeTab === 2 ? 'selected' : ''
									}`}>
									Courses
								</span>
								{activeTab === 2 && (
									<svg
										style={{ marginTop: '4px' }}
										xmlns='http://www.w3.org/2000/svg'
										width='43'
										height='2'
										viewBox='0 0 43 2'
										fill='none'>
										<path
											d='M1.16669 1H41.1667'
											stroke='#FAA53D'
											stroke-width='2'
											stroke-linecap='square'
										/>
									</svg>
								)}
							</div>
						</Tab>

						<Tab>
							<div
								className='bottom-nav-tab-list-item-box'
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								{activeTab === 3 ? <SelectedProfile /> : <Profile />}
								<span
									className={`bottom-nav-card-tag-title ${
										activeTab === 2 ? 'selected' : ''
									}`}>
									Profile
								</span>
								{activeTab === 3 && (
									<svg
										style={{ marginTop: '4px' }}
										xmlns='http://www.w3.org/2000/svg'
										width='43'
										height='2'
										viewBox='0 0 43 2'
										fill='none'>
										<path
											d='M1.16669 1H41.1667'
											stroke='#FAA53D'
											stroke-width='2'
											stroke-linecap='square'
										/>
									</svg>
								)}
							</div>
						</Tab>
					</TabList>
				}
			</Tabs>
		</div>
	);
};

export default BottomNavigation;
