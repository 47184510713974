import React from 'react';
import { ContactUs } from '../../utils/utils';

function Support() {
	return (
		<div
			style={{
				paddingTop: '32px',
				paddingLeft: '16px',
				paddingRight: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}>
			<h2
				style={{
					marginTop: '12px',
					color: '#1A1A1A',
					fontFamily: 'Poppins',
					fontSize: '24px',
					fontStyle: 'normal',
					fontWeight: 500,
				}}>
				{ContactUs.title}
			</h2>
			<p
				style={{
					marginTop: '12px',
					color: '#757575',
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontStyle: 'normal',
				}}>
				{ContactUs.subtitle}
			</p>
			<p
				style={{
					marginTop: '12px',
					color: '#757575',
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontStyle: 'normal',
				}}>
				{ContactUs.body}
			</p>
			<p
				style={{
					marginTop: '12px',
					color: '#757575',
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontStyle: 'normal',
				}}>
				Email: <a href={'mailto:' + ContactUs.email}>{ContactUs.email}</a>
			</p>
			<p
				style={{
					marginTop: '12px',
					color: '#757575',
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontStyle: 'normal',
				}}>
				Whatsapp:{' '}
				<a href={'https://wa.me/+919555434844'} target='_blank'>
					{ContactUs.phone}
				</a>
			</p>
		</div>
	);
}

export default Support;
