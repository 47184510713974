import BottomSheet from '../../../components/model/BottomSheet';
import './CourseGiftModal.css';

import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

import closeIcon from '../../../assets/close-cta.svg';
import courseGift from '../../../assets/webp-images/course-gift.png';
import { Mixpanel } from '../../../analytics/Mixpanel';

interface CourseGiftModalProps {
	showCourseGiftModal: boolean;
	onHideCourseGiftModal: () => void;
	handleDownloadClick: () => void;
	title: string;
}

function CourseGiftModal({
	showCourseGiftModal,
	onHideCourseGiftModal,
	handleDownloadClick,
	title,
}: CourseGiftModalProps) {
	const handleCrossClick = () => {
		Mixpanel.track('click_cross_button_lead_magnet', {
			event_page: 'course_landing_page_bottom_sheet',
			event_action: 'clicked',
		});
		onHideCourseGiftModal();
	};

	return (
		<BottomSheet
			customStyle={{
				height: 'max-content',
				zIndex: '10000000001',
				backgroundColor: '#1b1625',
			}}
			show={showCourseGiftModal}
			showHeader={false}
			hideOnOverlayClick={false}
			title=''
			onHide={onHideCourseGiftModal}
			content={
				<div className='course-gift-modal'>
					<img
						onClick={handleCrossClick}
						className='course-gift-modal-close'
						src={closeIcon}
						alt=''
					/>
					<img className='course-gift-modal-image' src={courseGift} alt='' />
					<div className='course-gift-modal-text'>
						<p className='course-gift-modal-title'>{title}</p>
						<p className='course-gift-modal-subtitle'>
							*Limited time offer for you. Avail it soon.
						</p>
					</div>
					<PrimaryButton
						onClick={handleDownloadClick}
						style={{
							width: '100%',
							marginBottom: '12px',
							fontSize: '14px',
							height: '44px',
						}}>
						Download for Free
					</PrimaryButton>
				</div>
			}
		/>
	);
}

export default CourseGiftModal;
