import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { storage } from '../../../services/Firebase';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import LeftDisabledArrow from '../../../assets/new-icons/chevron-left-disabled.svg';
import LeftEnabledArrow from '../../../assets/new-icons/chevron-left-enabled.svg';
import RightDisabledArrow from '../../../assets/new-icons/chevron-right-disabled.svg';
import RightEnabledArrow from '../../../assets/new-icons/chevron-right-enabled.svg';

type Props = {
	item: {
		icon: string;
		metaData?: {
			duration: string;
			title: string;
			type: string;
			typeColor: string;
		}[];
		subtitle: string;
		title: string;
	};
	divider?: boolean;
};

const CourseWhatYouGetItem: React.FC<Props> = ({ item, divider }) => {
	const [imgUrl, setImgUrl] = useState<string | null>(null);
	const [currentIndex, setCurrentIndex] = useState(0);

	const swiperRef = useRef<any>(null);

	const onBackClick = () => {
		if (!!item.metaData && currentIndex > 0) {
			swiperRef.current?.slidePrev();
		}
	};
	const onNextClick = () => {
		if (!!item.metaData && currentIndex < item.metaData.length - 2) {
			swiperRef.current?.slideNext();
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, item.icon);
				const url = await getDownloadURL(imageRef);
				setImgUrl(url);
			} catch (error) {}
		})();
	}, [item]);

	return (
		<>
			<div className='course-what-you-get-item'>
				<div className='course-what-you-get-item-icon'>
					{imgUrl && <img src={imgUrl} alt='' />}
				</div>
				<div className='course-what-you-get-item-info'>
					<p className='course-what-you-get-item-info-title'>{item.title}</p>
					<p className='course-what-you-get-item-info-subtitle'>
						{item.subtitle}
					</p>
				</div>
			</div>
			<Swiper
				onSlideChange={(swiper: any) => {
					setCurrentIndex(swiper.activeIndex);
				}}
				onSwiper={(swiper: any) => {
					swiperRef.current = swiper;
				}}
				slidesPerView={2.5}
				style={{ marginTop: '12px' }}>
				{!!item.metaData &&
					item.metaData.map((data, index: any) => {
						return (
							<SwiperSlide>
								<div
									style={{
										maxWidth: '120px',
										height: '130px',
										background: '#1b1625',
										borderRadius: '12px',
										padding: '8px',
										display: 'flex',
										flexDirection: 'column',
									}}>
									<p
										style={{
											fontFamily: 'Gordita-Medium',
											fontSize: '16px',
											color: 'white',
											marginBottom: 0,
										}}>
										{data.title}
									</p>
									<p
										style={{
											color: '#' + data.typeColor,
											marginBottom: 0,
											fontFamily: 'Gordita-Regular',
											fontSize: '14px',
										}}>
										{data.type}
									</p>
									<p
										style={{
											marginTop: 'auto',
											marginBottom: '0',
											color: 'white',
											fontFamily: 'Gordita-Regular',
										}}>
										{data.duration}
									</p>
								</div>
							</SwiperSlide>
						);
					})}
			</Swiper>
			{!!item.metaData && item.metaData.length > 2 && (
				<div style={{ display: 'flex', marginTop: '12px' }}>
					<div
						onClick={onBackClick}
						style={{
							width: '36px',
							marginRight: '12px',
							height: '36px',
							display: 'flex',
							marginLeft: 'auto',
							border: '1px solid #4a4a4a',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							opacity: currentIndex <= 0 ? 0.5 : 1,
						}}>
						<img
							src={LeftDisabledArrow}
							alt=''
							style={{ width: '20px', height: '20px' }}
						/>
					</div>
					<div
						onClick={onNextClick}
						style={{
							width: '36px',
							height: '36px',
							display: 'flex',
							border: '1px solid #4a4a4a',
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: '8px',
							opacity: currentIndex >= item.metaData.length - 2 ? 0.5 : 1,
						}}>
						<img
							src={RightDisabledArrow}
							alt=''
							style={{ width: '20px', height: '20px' }}
						/>
					</div>
				</div>
			)}
			{divider && <div className='course-what-you-get-item-divider'></div>}
		</>
	);
};

export default CourseWhatYouGetItem;
