import React, {useEffect, useState} from 'react';
import SignUpForm from './SignUpForm';
import Otp from './Otp';
import {
    checkUserExistOrNot,
    signUpUser,
    updateUser,
    verifyOtp,
} from '../../services/Firebase';
import {Mixpanel} from '../../analytics/Mixpanel';
import {useLocation, useNavigate} from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
import {useDispatch} from 'react-redux';
import {addUsers, updateBackScreen} from '../../store/features/AuthSlice';
import {STATES as ON_BOARDING_SCREENS} from './OnBoarding';
import {isEmpty} from '../../utils/utils';
import {events, events_action, events_page} from '../../analytics/Events';
import ErrorComponent from '../../components/error/Error';

export enum STATES {
    SIGNUP_FORM,
    OTP,
}

function SignUp() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation();
    const screenValue = location.state?.screen
        ? location.state.screen
        : STATES.SIGNUP_FORM;
    const user = location.state?.user ? location.state.user : null;
    const userPreferences = location.state?.parsedUserPreferences
        ? location.state.parsedUserPreferences
        : {};
    const nameArray = userPreferences?.name?.split(' ');
    const firstName = nameArray?.[0];
    const lastName = nameArray?.slice(1)?.join(' ');
    const dispatch = useDispatch();
    const [state, setState] = useState<STATES>(screenValue);
    const [signUpData, setSignUpData] = useState<any>(user);
    const [userAlreadyExist, setUserAlreadyExist] = useState(false);

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();


    const onFormSubmitHandler = async (signUpData: any) => {
        const checkUserExistOrNotResponse = await checkUserExistOrNot(
            signUpData?.phone,
            'LOGIN'
        );
        if (
            checkUserExistOrNotResponse?.status === 409 &&
            checkUserExistOrNotResponse?.message ===
            'User already exists. Show otp screen'
        ) {
            Mixpanel.track('view_otp_screen', {
                event_page: 'otp_screen',
                event_action: events_action.viewed,
                page: 'signup',
            });

            setUserAlreadyExist(true);
            setState(STATES.OTP);
            setSignUpData(signUpData);

        } else {
            // console.log("checkUserExistOrNotResponse else", checkUserExistOrNotResponse?.message);
            setUserAlreadyExist(false);
            setSignUpData(signUpData);
            const formDataMergedFromFirebaseAndSingUpForm = {
                userPartOfWhatsappCommunity: false,
                userPreferences: userPreferences,
                name: userPreferences?.name,
                ...signUpData,
                trialStartDate: moment(userPreferences?.trialStartDate).format(
                    'D MMM YYYY'
                ),
            };
            try {
                const userSignUpResult = await signUpUser(
                    formDataMergedFromFirebaseAndSingUpForm,
                    'SIGNUP'
                );
                console.log("userSignUpResult", userSignUpResult);
                if (userSignUpResult) {
                    setLoader(false);
                    try {
                        updateUser(userSignUpResult.userId, {
                            isPlanOnboardingComplete: true,
                        }).then();
                    } catch (e) {
                        console.log('updateUser err', e);
                    }
                    Mixpanel.identify(userSignUpResult.userId);
                    Mixpanel.people.set({
                        $id: userSignUpResult.userId,
                        $first_name: firstName,
                        $last_name: lastName,
                        // $email:signUpData.email,
                        $name: userPreferences?.name,
                        $phone: signUpData?.phone || signUpData?.phoneNumber,
                        $trial_start_date: moment(userPreferences?.trialStartDate).format(
                            'D MMM YYYY'
                        ),
                        $priorities: userPreferences?.priorities?.map(
                            (item: any) => item?.title
                        ),
                        $age_group: userPreferences?.age?.title,
                        $location: userPreferences?.location,
                        $language: userPreferences?.language,
                        $goals: userPreferences?.goal?.title,
                        $experience: userPreferences?.userExperience,
                        $physical_intensity: userPreferences?.physical_activity,
                    });
                    Mixpanel.track('SignUp', formDataMergedFromFirebaseAndSingUpForm);
                    dispatch(addUsers(formDataMergedFromFirebaseAndSingUpForm));
                    onProceedHandler(
                        userSignUpResult.userId,
                        formDataMergedFromFirebaseAndSingUpForm
                    );
                } else {
                    setLoader(false);
                    setError('Error creating account');
                }
            } catch (e) {
            }
        }
    };

    const onOtpVerifyHandler = async (otp: any) => {
        setLoader(true);
        if (userAlreadyExist) {
            try {
                const userLoginResult = await verifyOtp(otp, signUpData?.phone, '');
                if (userLoginResult?.status === 200) {
                    // setIsLoginSuccessful(true)
                    Mixpanel.identify(userLoginResult.userData?.uid);
                    Mixpanel.track(events.view_login_successful, {
                        event_page: events_page.sign_up,
                        event_action: events_action.viewed,
                        response: {
                            phone: signUpData?.phone,
                        },
                    });
                    let isProfileCompleted = true;
                    try {
                        if (
                            moment(
                                new Date(userLoginResult.userData?.createdAt?._seconds * 1000)
                            ).isAfter(moment('Jul 19 2024')) &&
                            !userLoginResult.userData?.isProfileCompleted
                        ) {
                            isProfileCompleted = false;
                        } else {
                            isProfileCompleted = true;
                        }
                    } catch (e) {
                        console.log('signupdate logi errn', e);
                    }

                    const user = {
                        phone: userLoginResult?.userData?.phone,
                        name: userLoginResult?.userData?.name,
                        phoneNumber: userLoginResult?.userData?.phoneNumber,
                        uid: userLoginResult.userData?.uid,
                        isProfileCompleted: isProfileCompleted,
                    };
                    dispatch(addUsers(userLoginResult.userData));
                    window.localStorage.setItem('user', JSON.stringify(user));
                    setTimeout(() => {
                        navigate('/home', {
                            state: {user: userLoginResult?.userData},
                            replace: true,
                        });
                    }, 500);
                } else {
                    setLoader(false);
                    setError('Please enter the correct OTP');
                    Mixpanel.track('otp_incorrect', {
                        event_page: events_page.otp_screen,
                        event_action: events_action.viewed,
                    });
                }
            } catch (e) {
            }
        } else {
            const formDataMergedFromFirebaseAndSingUpForm = {
                otp: otp,
                userPartOfWhatsappCommunity: false,
                userPreferences: userPreferences,
                name: userPreferences?.name,
                firstName,
                lastName,
                isProfileCompleted: false,
                ...signUpData,
                trialStartDate: moment(userPreferences?.trialStartDate).format(
                    'D MMM YYYY'
                ),
            };
            try {
                const userSignUpResult = await signUpUser(
                    formDataMergedFromFirebaseAndSingUpForm,
                    'SIGNUP'
                );
                if (userSignUpResult) {
                    setLoader(false);
                    try {
                        updateUser(userSignUpResult.userId, {
                            isPlanOnboardingComplete: true,
                        }).then();
                    } catch (e) {
                        console.log('updateUser err', e);
                    }
                    Mixpanel.identify(userSignUpResult.userId);
                    Mixpanel.people.set({
                        $id: userSignUpResult?.userId,
                        $first_name: firstName,
                        $last_name: lastName,
                        // $email:signUpData?.email,
                        $name: userPreferences?.name,
                        $phone: signUpData?.phone || signUpData?.phoneNumber,
                        $trial_start_date: moment(userPreferences?.trialStartDate).format(
                            'D MMM YYYY'
                        ),
                        $priorities: userPreferences?.priorities?.map(
                            (item: any) => item?.title
                        ),
                        $age_group: userPreferences?.age?.title,
                        $location: userPreferences?.location,
                        $language: userPreferences?.language,
                        $goals: userPreferences?.goal?.title,
                        $experience: userPreferences?.userExperience,
                        $physical_intensity: userPreferences?.physical_activity,
                    });
                    Mixpanel.track('SignUp', formDataMergedFromFirebaseAndSingUpForm);
                    // Update redux with user data
                    dispatch(addUsers(formDataMergedFromFirebaseAndSingUpForm));
                    onProceedHandler(
                        userSignUpResult.userId,
                        formDataMergedFromFirebaseAndSingUpForm
                    );
                } else {
                    setLoader(false);
                    setError('Please enter the correct OTP');
                    Mixpanel.track('otp_incorrect', {
                        event_page: events_page.otp_screen,
                        event_action: events_action.clicked,
                    });
                }
            } catch (e) {
            }
        }
    };

    const onProceedHandler = (
        uid: string,
        formDataMergedFromFirebaseAndSingUpForm: any
    ) => {
        setLoader(true);
        try {
            const user = {
                isProfileCompleted:
                formDataMergedFromFirebaseAndSingUpForm?.isProfileCompleted,
                phone: formDataMergedFromFirebaseAndSingUpForm?.phone,
                name: formDataMergedFromFirebaseAndSingUpForm?.name,
                phoneNumber: formDataMergedFromFirebaseAndSingUpForm?.phoneNumber,

                uid: uid,
            };
            console.log('sign up user', user);
            window.localStorage.setItem('user', JSON.stringify(user));
            navigate('/sign-up-successful');
            setLoader(false);
        } catch (e) {
        }
    };
    const onBackArrowClick = () => {
        setLoader(true);
        if (state === STATES.SIGNUP_FORM && !isEmpty(userPreferences)) {
            dispatch(updateBackScreen(ON_BOARDING_SCREENS?.CONGRATULATIONS));
            navigate(-1);
            return;
        }
        try {
            setLoader(false);
            setState((prevState) => {
                switch (prevState) {
                    case STATES.OTP:
                        return STATES.SIGNUP_FORM;
                    default:
                        return prevState;
                }
            });
        } catch (e) {
        }
    };
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    const closeErrorPopup = () => {
        setError('');
    };

    return (
        <div
            style={{
                width: '100%',
                height: '95%',
                overflow: 'hidden',
                background: '#F4F4F4',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
            }}>
            {loader && <Loader/>}
            {error && (
                <ErrorComponent
                    onClose={closeErrorPopup}
                    customStyles={{
                        position: 'fixed',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        top: 10,
                        left: 10,
                        right: 10,
                    }}
                    message={error}
                />
            )}

            {state === STATES.SIGNUP_FORM && (
                <SignUpForm
                    setError={setError}
                    name={userPreferences?.name}
                    onBackArrowClick={onBackArrowClick}
                    onFormSubmitHandler={onFormSubmitHandler}
                    user={user}
                />
            )}
            {state === STATES.OTP && (
                <Otp
                    onBackArrowClick={onBackArrowClick}
                    page={'signup'}
                    loader={loader}
                    onOtpVerifyHandler={onOtpVerifyHandler}
                    mobileNumber={signUpData?.phone}
                />
            )}
            <ToastContainer/>
        </div>
    );
}

export default SignUp;
