import React from 'react';
import { getRandomDarkColor, getRandomLightColor } from '../../utils/utils';
import './CustomerReviews.css';
interface ProductProps {
	index: number;
	imageSrc?: string;
	period: string;
	bio: string;
	name: string;
	rating: number;
	count: number;
	page?: string;
	dark?: boolean;
}

const CustomerReview: React.FC<ProductProps> = ({
	count,
	imageSrc,
	period,
	bio,
	name,
	rating,
	index,
	page,
	dark,
}) => {
	const firstChar = name.charAt(0).toUpperCase();
	return (
		<>
			<div
				className={'horizontal-container plan-customer-review-container'}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
					alignItems: 'start',
					gap: '10px',
					background: dark ? '#232323' : 'var(--White, #FFF)',
					maxWidth: '354px',
					position: 'relative',
					marginTop: '6px',
					padding: dark ? '12px' : '12px 0',
					borderRadius: dark ? '12px' : 0,
					border: dark ? '1px solid #4a4a4a' : '',
					borderBottom: !dark ? '1px dashed #e4e4e4' : '1px solid #4a4a4a',
					// padding: page === 'course' ? '12px' : '12px 0',
					// border: page === 'course' ? '1px solid #e4e4e4' : '',
					// borderRadius: page === 'course' ? '8px' : '',
				}}>
				{!dark && (
					<div
						style={{
							display: 'flex',
							padding: '8px 12px',
							backgroundColor: getRandomLightColor(index),
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<span
							style={{
								color: getRandomDarkColor(index),
								textAlign: 'center',
								fontFamily: 'Manrope',
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 800,
								lineHeight: '24px',
							}}>
							{firstChar}
						</span>
					</div>
				)}
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span
							style={{ color: dark ? '#ffffff' : '#1b1625' }}
							className={'plan-description-review-name'}>
							{name}
						</span>
						<span
							style={{
								marginLeft: '5px',
								alignSelf: 'center',
								fontFamily: 'Gordita-Medium',
								fontSize: '14px',
								color: dark ? '#ffffff' : '#1b1625',
							}}>
							{rating}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M9.04894 2.92705C9.3483 2.00574 10.6517 2.00574 10.9511 2.92705L12.0206 6.21885C12.1545 6.63087 12.5385 6.90983 12.9717 6.90983H16.4329C17.4016 6.90983 17.8044 8.14945 17.0207 8.71885L14.2205 10.7533C13.87 11.0079 13.7234 11.4593 13.8572 11.8713L14.9268 15.1631C15.2261 16.0844 14.1717 16.8506 13.388 16.2812L10.5878 14.2467C10.2373 13.9921 9.7627 13.9921 9.41221 14.2467L6.61204 16.2812C5.82833 16.8506 4.77385 16.0844 5.0732 15.1631L6.14277 11.8713C6.27665 11.4593 6.12999 11.0079 5.7795 10.7533L2.97933 8.71885C2.19562 8.14945 2.59839 6.90983 3.56712 6.90983H7.02832C7.46154 6.90983 7.8455 6.63087 7.97937 6.21885L9.04894 2.92705Z'
									fill='#F7871B'
								/>
							</svg>
						</span>
					</div>
					<span className={'plan-description-review-period'}>
						{isNaN(Number(period)) ? period : `Seeker for ${period}+ years`}
					</span>
					<span className={'plan-description-review-bio'}>{bio}</span>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'start',
						alignSelf: 'center',
						position: 'absolute',
						right: 12,
						top: 12,
					}}></div>
			</div>
		</>
	);
};
export default CustomerReview;
