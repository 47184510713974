import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	bottomNavBar: {
		currentTabIndex: 2,
	},
};

export const BottomNavSlice = createSlice({
	name: 'bottomSlice',
	initialState,
	reducers: {
		updateCurrentTab: (state, action) => {
			state.bottomNavBar.currentTabIndex = action.payload;
		},
	},
});

export const { updateCurrentTab } = BottomNavSlice.actions;

export default BottomNavSlice.reducer;
